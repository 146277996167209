export const registerUser = async (data) => {
  await new Promise((resolve) => setTimeout(resolve, 1000));

  if (data.password !== data.confirmPassword) {
    return { errorMessage: "Passwords do not match", error: 501 };
  }
  try {
    const response = await fetch("http://localhost:5000/api/users/signup", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const responseData = await response.json();
    console.log(responseData);
    return responseData;
  } catch (error) {
    return { errorMessage: "An error occurred. Please try again.", error: 500 };
  }
};

export const loginUser = async (email, password) => {
  try {
    const response = await fetch("http://localhost:5000/api/users/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email, password: password }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const contactMe = async (name, message, email) => {
  try {
    const response = await fetch(
      "https://c6jz5udbpf.execute-api.eu-west-1.amazonaws.com/dev/items",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, message }),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    return { errorMessage: "An error occurred. Please try again.", error: 500 };
  }
};
