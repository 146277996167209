import {
  Modal,
  Input,
  Row,
  Checkbox,
  Button,
  Text,
  Loading,
} from "@nextui-org/react";
import { Mail, Password, UserIcon } from "../assets/vectorImages";
import { useState, useEffect, useRef, useCallback } from "react";
import { registerUser, loginUser } from "../util/services";
import { useDispatch } from "react-redux";
import { authActions } from "../store/auth-slice";

const words = ["Welcome to Artisan Geeks"];

export default function Auth(props) {
  const [passwordValue, updatePasswordValue] = useState("");
  const [confirmPasswordValue, updateConfirmPasswordValue] = useState("");
  const [emailValue, updateEmailValue] = useState("");
  const [isLoading, UpdateIsLoading] = useState(false);
  const [usernameValue, updateUsernameValue] = useState("");
  const [index, setIndex] = useState(0);
  const [subIndex, setSubIndex] = useState(0);
  const [reverse, setReverse] = useState(false);
  const [errorMessage, updateErrorMessage] = useState("");
  const [errorStatus, updateErrorStatus] = useState("");
  const confirmPassword = useRef("");
  const password = useRef("");
  const email = useRef("");
  const username = useRef("");
  const dispatch = useDispatch();

  // typeWriter
  useEffect(() => {
    const word = words[index];
    const isLastWord = index === words.length - 1;
    const isAtEndOfWord = subIndex === word.length;
    const isAtStartOfWord = subIndex === 0;
    const isFirstWord = index === 0;
    const timeoutDuration = reverse ? 30 : isAtEndOfWord ? 50 : 80;

    if (
      !isAtEndOfWord &&
      ((isLastWord && subIndex === word.length + 1) || (!isLastWord && reverse))
    ) {
      setReverse(true);
      return;
    }

    if (isAtStartOfWord && reverse && !isFirstWord) {
      setReverse(false);
      setIndex((prev) => prev + 1);
      return;
    }

    const timeout = setTimeout(() => {
      setSubIndex((prev) => prev + (reverse ? -1 : 1));
    }, timeoutDuration);

    return () => clearTimeout(timeout);
  }, [subIndex, index, reverse]);

  //Form Handlers

  const passwordHandler = useCallback(() => {
    updateErrorStatus("");
    updatePasswordValue(password.current.value);
  }, [password]);
  const confirmPasswordHandler = useCallback(() => {
    updateErrorStatus("");
    updateConfirmPasswordValue(confirmPassword.current.value);
  }, [confirmPassword]);

  const emailHandler = useCallback(() => {
    updateErrorStatus("");
    updateEmailValue(email.current.value);
  }, [email]);

  const usernameHandler = useCallback(() => {
    updateErrorStatus("");
    updateUsernameValue(username.current.value);
  }, [username]);

  //Submit Handler

  const loginSubmitHandler = async (e) => {
    e.preventDefault();

    UpdateIsLoading(true);
    let responseData;

    props.authMode
      ? (responseData = await loginUser(emailValue, passwordValue))
      : (responseData = await registerUser({
          name: usernameValue,
          email: emailValue,
          confirmPassword: confirmPasswordValue,
          password: passwordValue,
        }));

    UpdateIsLoading(false);
    console.log(responseData);

    if (responseData.error) {
      updateErrorStatus(responseData.error);
      updateErrorMessage(responseData.errorMessage);
      return;
    }
    updateErrorStatus("");
    dispatch(authActions.toggleLogin());
    props.closeModalHandler(false);
  };

  return (
    <Modal
      closeButton
      blur
      aria-labelledby="modal-title"
      open={props.modalIsVisible}
      onClose={props.closeModalHandler}
    >
      <form onSubmit={loginSubmitHandler} autoComplete="off">
        <Modal.Header>
          <Text id="modal-title" size={18}>
            {`${words[index].substring(0, subIndex)}`}
          </Text>
        </Modal.Header>
        {errorStatus === 500 && (
          <Text size={18} color="error">
            {errorMessage}
          </Text>
        )}
        {errorStatus === 403 && (
          <Text size={18} color="error">
            {errorMessage}
          </Text>
        )}
        <Modal.Body>
          {!props.authMode && (
            <Input
              bordered
              fullWidth
              color="primary"
              size="lg"
              placeholder="Username"
              autoComplete={"off"}
              contentLeft={<UserIcon fill="currentColor" />}
              required
              ref={username}
              onChange={usernameHandler}
            />
          )}

          <Input
            bordered
            fullWidth
            required
            color="primary"
            size="lg"
            placeholder="Email"
            contentLeft={<Mail fill="currentColor" />}
            autoComplete={"off"}
            ref={email}
            onChange={emailHandler}
          />

          <Input.Password
            bordered
            fullWidth
            color="primary"
            autoComplete={"off"}
            size="lg"
            placeholder="Password"
            contentLeft={<Password fill="currentColor" />}
            required
            ref={password}
            onChange={passwordHandler}
          />

          {!props.authMode && (
            <Input.Password
              bordered
              fullWidth
              color="primary"
              autoComplete={"off"}
              size="lg"
              placeholder="Confirm Password"
              contentLeft={<Password fill="currentColor" />}
              ref={confirmPassword}
              required
              onChange={confirmPasswordHandler}
            />
          )}
          {errorStatus === 501 && <Text color="error">{errorMessage}</Text>}

          {props.authMode && (
            <Row justify="space-between">
              <Checkbox>
                <Text size={14}>Remember me</Text>
              </Checkbox>
            </Row>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            color="primary"
            light
            size="xs"
            onPress={
              props.authMode ? props.signUpModeHandler : props.loginModeHandler
            }
          >
            {/*             {props.authMode
              ? "Don't have an account? Register here."
              : "Already have an account? Log in here."} */}
          </Button>
          {/*           <Button color="error" flat auto onPress={props.closeModalHandler}>
            Close
          </Button> */}

          {/*           {props.authMode ? (
            <Button type={"submit"} color="primary" auto disabled>
              {isLoading ? <Loading color="gradient" type="points" /> : "Login"}
            </Button>
          ) : (
            <Button type={"submit"} color="gradient" auto disabled>
              {isLoading ? <Loading color="white" type="points" /> : "Sign Up"}
            </Button>
          )} */}
        </Modal.Footer>
      </form>
    </Modal>
  );
}
