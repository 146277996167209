import {
  Grid,
  Text,
  Container,
  Input,
  Spacer,
  Card,
  Textarea,
  Row,
  Button,
  Image,
  Loading,
} from "@nextui-org/react";
import nebula from "../assets/spaceship.png";
import stars from "../assets/swirl.png";
import { motion } from "framer-motion";
import { contactMe } from "../util/services";
import { useState, useRef, useCallback } from "react";

const fadeIn = (direction, type, delay, duration) => {
  return {
    hidden: {
      x: direction === "left" ? 200 : direction === "right" ? -200 : 0,
      y: direction === "up" ? 200 : direction === "down" ? -200 : 0,
      opacity: 0,
    },
    show: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: {
        type: type,
        delay: delay,
        duration: duration,
        ease: "easeOut",
      },
    },
  };
};

export const ContactMe = () => {
  const [nameValue, updateNameValue] = useState("");
  const [messageValue, updatemessageValue] = useState("");
  const [emailValue, updateEmailValue] = useState("");
  const [isLoading, UpdateIsLoading] = useState(false);
  const [errorMessage, updateErrorMessage] = useState("");
  const [errorStatus, updateErrorStatus] = useState("");

  const name = useRef("");
  const message = useRef("");
  const email = useRef("");

  const nameHandler = useCallback(() => {
    updateNameValue(name.current.value);
  }, [name]);

  const messageHandler = useCallback(() => {
    updatemessageValue(message.current.value);
  }, [message]);

  const emailHandler = useCallback(() => {
    updateEmailValue(email.current.value);
  }, [email]);

  const contactMeSubmitHandler = async () => {
    UpdateIsLoading(true);
    let responseData;
    responseData = await contactMe(nameValue, messageValue, emailValue);
    console.log(responseData);
    UpdateIsLoading(false);

    if (responseData.error) {
      updateErrorStatus(responseData.error);
      updateErrorMessage(responseData.errorMessage);
      return;
    }
    updateErrorStatus("");
  };

  return (
    <form onSubmit={contactMeSubmitHandler}>
      <Grid.Container justify="center">
        <Grid md={8} justify="space-between">
          <Grid md={6}>
            <motion.div
              variants={fadeIn("right", "spring", 0.5, 1)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
            >
              <Card
                style={{
                  background:
                    "linear-gradient(90deg, rgba(13,26,46,1) 13%, rgba(11,19,44,1) 42%, rgba(10,14,42,1) 61%, rgba(9,10,40,1) 77%, rgba(7,5,38,1) 100%)",
                }}
              >
                <Container>
                  <Spacer y={1} />
                  <Grid.Container justify="start">
                    <Grid md={12}>
                      <Grid>
                        <Text h4>Get in touch</Text>
                      </Grid>
                    </Grid>

                    <Grid md={12}>
                      <Text weight="bold" h1 size={50}>
                        Contact Me
                      </Text>
                    </Grid>
                    {errorStatus && (
                      <Grid justify="center" md={12}>
                        <Text weight="bold" h5 color="error">
                          {errorMessage}
                        </Text>
                      </Grid>
                    )}
                  </Grid.Container>
                  <Spacer y={2} />
                  <Text h5>Your Name</Text>
                  <Spacer y={0.5} />
                  <Input
                    size="xl"
                    placeholder="Full Name"
                    fullWidth={"true"}
                    color="primary"
                    ref={name}
                    onChange={nameHandler}
                  />
                  <Spacer y={0.5} />
                  <Text h5> Your email</Text>
                  <Input
                    size="xl"
                    placeholder="Email Address"
                    fullWidth={"true"}
                    ref={email}
                    onChange={emailHandler}
                  />
                  <Spacer y={0.5} />
                  <Text h5>Your Message</Text>
                  <Spacer y={0.5} />
                  <Textarea
                    placeholder="Message"
                    rows={4}
                    size="xl"
                    fullWidth={"true"}
                    ref={message}
                    onChange={messageHandler}
                  />
                  <Spacer y={1} />
                  <Card.Footer>
                    <Row justify="flex-end">
                      <Button
                        disabled={isLoading}
                        auto
                        color="gradient"
                        size="lg"
                        type="submit"
                        onPress={contactMeSubmitHandler}
                      >
                        {isLoading ? (
                          <Loading color="white" type="points" />
                        ) : (
                          "Send"
                        )}
                      </Button>
                    </Row>
                  </Card.Footer>
                  <Spacer y={1} />
                </Container>
              </Card>
            </motion.div>
          </Grid>

          {/*       
        <Grid
          md={4}
          style={{
            backgroundImage: `url(${stars})`,
            backgroundSize: "auto 100%",
            backgroundPosition: "center center",
          }}
        >
        
        </Grid> */}
          <Grid md={6} style={{ position: "relative" }}>
            <div
              id="ContactMe"
              style={{
                position: "absolute",
                top: "-50px",
                left: "-50px",
                right: "-50px",
                bottom: "-50px",
                backgroundImage: `url(${stars})`,
                backgroundSize: "cover",
                zIndex: 0,
              }}
            ></div>{" "}
            <Spacer y={1} />
            <motion.div
              variants={fadeIn("right", "spring", 0.5, 2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
            >
              <Image src={nebula} />
            </motion.div>
          </Grid>
        </Grid>
      </Grid.Container>
    </form>
  );
};
