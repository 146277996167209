import { Text, Container, Row, Col, Grid } from "@nextui-org/react";
import { motion } from "framer-motion";

const cardVariants = {
  offscreen: {
    y: -100,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 0.8,
    transition: {
      type: "spring",

      duration: 2,
    },
  },
};

export const Overview = () => {
  return (
    <>
      <motion.div
        variants={cardVariants}
        className="card-container"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.8 }}
      >
        <Container>
          <Grid.Container justify="center">
            <Grid md={8}>
              <Grid md={6}>
                <Text h4>INTRODUCTION</Text>
              </Grid>
            </Grid>
            <Grid md={8}>
              <Grid md={6}>
                <Text weight="bold" h1 size={60}>
                  Overview.
                </Text>
              </Grid>
            </Grid>
          </Grid.Container>
        </Container>
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: {
            duration: 2,
            ease: "easeOut",
          },
        }}
      >
        <Container>
          <Grid.Container justify="center">
            <Grid md={8}>
              <Grid md={8}>
                <Text size={20} style={{ textAlign: "justify" }}>
                  As a seasoned professional in managing development teams, I
                  possess extensive experience in leading and guiding teams
                  towards successful project outcomes. I am highly skilled in
                  collaborating closely with clients, project stakeholders, and
                  team members to ensure effective communication and alignment
                  throughout the development process. Through my leadership and
                  management abilities, I am adept at fostering a culture of
                  teamwork, open communication, and continuous learning within
                  the team.
                </Text>
              </Grid>
            </Grid>
          </Grid.Container>
        </Container>
      </motion.div>
    </>
  );
};
