import { Grid, Text, Container, Spacer } from "@nextui-org/react";
import { Experiences } from "./Experiences";
import { Overview } from "./Overview";
import { OverviewCard } from "./OverviewCard";
import table from "../assets/atro5.png";
import { ContactMe } from "./ContactMe";
import { SkillsAvatar } from "./SkillsAvatar";

export const HomePageItems = () => {
  return (
    <>
      <img
        src="https://nextui.org/gradients/blue-purple-1.svg"
        style={{ position: "absolute", top: -200, left: 0, zIndex: 0 }}
        alt="test"
      />
      <img
        src="https://nextui.org/gradients/blue-purple-1.svg"
        style={{ position: "absolute", top: -200, left: 0, zIndex: 0 }}
        alt="test"
      />
      <img
        src="https://nextui.org/gradients/blue-purple-1.svg"
        style={{ position: "absolute", top: 1000, right: 0, zIndex: 0 }}
        alt="test"
      />
      <img
        src="https://nextui.org/gradients/blue-purple-1.svg"
        style={{ position: "absolute", top: 250, right: 50, zIndex: 0 }}
        alt="test"
      />

      <img
        src="https://nextui.org/gradients/blue-purple-1.svg"
        style={{ position: "absolute", top: 2000, left: 50, zIndex: 0 }}
        alt="test"
      />
      <img
        src="https://nextui.org/gradients/blue-purple-1.svg"
        style={{ position: "absolute", top: 2000, right: 50, zIndex: 0 }}
        alt="test"
      />

      <img
        src="https://nextui.org/gradients/blue-purple-1.svg"
        style={{ position: "absolute", top: 2500, right: 50, zIndex: 0 }}
        alt="test"
      />

      <Container>
        <div id="home" />
        <Grid.Container justify="center">
          <Grid md={8}>
            <Grid md={6}>
              <Text
                h1
                css={{
                  color: "white",
                  display: "inline",
                }}
                weight="bold"
              >
                Hi, I'm
              </Text>
              <Text
                h1
                css={{
                  textGradient: "45deg, $blue600 -20%, $pink600 50%",
                  display: "inline",
                }}
                weight="bold"
              >
                &#160;Marthinus
              </Text>
            </Grid>
          </Grid>
        </Grid.Container>
      </Container>
      <Container>
        <Grid.Container justify="center">
          <Grid md={8}>
            <Grid md={6}>
              <Text
                h3
                css={{
                  color: "white",
                }}
              >
                I specialize in transforming your digital interface ideas into
                fully functional web and mobile applications that are custom
                tailored to your specific needs and preferences.
              </Text>
            </Grid>
          </Grid>
        </Grid.Container>
      </Container>
      <Spacer y={5}></Spacer>
      <div id="about"></div>
      <Container>
        <Grid.Container justify="center">
          <Grid>
            <img src={table} alt="table"></img>
          </Grid>
        </Grid.Container>
      </Container>
      <Spacer y={5}></Spacer>
      <Overview />
      <Spacer y={5}></Spacer>
      <OverviewCard />
      <Spacer y={5}></Spacer>
      <Container>
        <div id="experience"></div>
        <Grid.Container>
          <Grid xs={12} justify="center">
            <Text>WHAT I HAVE DONE SO FAR</Text>
          </Grid>
          <Grid xs={12} justify="center">
            <Text h1 size={70}>
              Work Experience.
            </Text>
          </Grid>
        </Grid.Container>
      </Container>
      <Container>
        <Experiences />
      </Container>
      <Spacer y={5} />
      <SkillsAvatar />
      <Spacer y={5} />
      <div id="contactMe"></div>
      <Container>
        <ContactMe />
      </Container>
      <Spacer y={10} />
    </>
  );
};
