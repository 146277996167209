import MainNavigation from "../components/shared/ui/MainNavigation";
import { Container, Spacer, Text } from "@nextui-org/react";

const ErrorPage = () => {
  return (
    <>
      <MainNavigation />
      <Container justify="center" align="center">
        <Spacer y={10} />

        <Text weight="bold">Oops, something went wrong!</Text>
        <Text>We'll probably fix this at some point...</Text>
      </Container>
    </>
  );
};

export default ErrorPage;
