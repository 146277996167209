import { Avatar, Grid } from "@nextui-org/react";
import css from "../assets/tech/css.png";
import git from "../assets/tech/git.png";
import html from "../assets/tech/html.png";
import javascript from "../assets/tech/javascript.png";
import mongodb from "../assets/tech/mongodb.png";
import nodejs from "../assets/tech/nodejs.png";
import react from "../assets/tech/reactjs.png";
import redux from "../assets/tech/redux.png";
import cypress from "../assets/tech/cypress.avif";
export const SkillsAvatar = () => {
  return (
    <Grid.Container gap={2} justify={"center"}>
      <Grid>
        <Avatar size="xl" src={css} zoomed />
      </Grid>
      <Grid>
        <Avatar size="xl" src={git} zoomed />
      </Grid>
      <Grid>
        <Avatar size="xl" src={html} zoomed />
      </Grid>
      <Grid>
        <Avatar size="xl" src={javascript} zoomed />
      </Grid>
      <Grid>
        <Avatar size="xl" src={mongodb} zoomed />
      </Grid>
      <Grid>
        <Avatar size="xl" src={nodejs} zoomed />
      </Grid>
      <Grid>
        <Avatar size="xl" src={react} zoomed />
      </Grid>
      <Grid>
        <Avatar size="xl" src={redux} zoomed />
      </Grid>
      <Grid>
        <Avatar size="xl" src={cypress} zoomed />
      </Grid>
    </Grid.Container>
  );
};
