import { Navbar, Text, Button, Avatar } from "@nextui-org/react";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { LinkedIn } from "../../../assets/vectorImages";

export default function MainNavigation(props) {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const homeHandleClickScroll = () => {
    const element = document.getElementById("home");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const aboutHandleClickScroll = () => {
    const element = document.getElementById("about");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const experienceHandleClickScroll = () => {
    const element = document.getElementById("experience");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const contactMeHandleClickScroll = () => {
    const element = document.getElementById("contactMe");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Navbar variant="sticky">
        <Navbar.Toggle showIn="xs" />
        <Navbar.Brand
          css={{
            "@xs": {
              w: "12%",
            },
          }}
        >
          <Text b color="inherit">
            Marthinus Strydom
          </Text>
        </Navbar.Brand>

        <Navbar.Content
          enableCursorHighlight
          activeColor="secondary"
          hideIn="xs"
          variant="highlight-rounded"
        >
          <Navbar.Link as={Link} onClick={homeHandleClickScroll}>
            Home
          </Navbar.Link>
          <Navbar.Link as={Link} onClick={aboutHandleClickScroll}>
            About
          </Navbar.Link>
          <Navbar.Link as={Link} onClick={experienceHandleClickScroll}>
            Experience
          </Navbar.Link>
          <Navbar.Link as={Link} onClick={contactMeHandleClickScroll}>
            Contact me
          </Navbar.Link>
        </Navbar.Content>

        <Navbar.Content
          css={{
            "@xs": {
              w: "12%",
              jc: "flex-end",
            },
          }}
        >
          <Navbar.Content>
            <Avatar
              squared
              as={Link}
              pointer
              to="https://www.linkedin.com/in/marthinus-strydom-26aaa513a/"
              target="_blank"
              size="md"
              src="https://cdn-icons-png.flaticon.com/512/179/179330.png"
              zoomed
            />
          </Navbar.Content>
          {!isLoggedIn ? (
            <>
              {/*           <Button
                auto
                color="gradient"
                shadow
                onPress={props.signUpModeHandler}
              >
                Sign up
              </Button> */}
              {/*               <Button
                auto
                color="gradient"
                shadow
                onPress={props.loginModeHandler}
              >
                Login
              </Button> */}
            </>
          ) : (
            <>
              <Button auto color="gradient" shadow onPress={props.loginHandler}>
                Log Out
              </Button>
            </>
          )}
        </Navbar.Content>

        <Navbar.Collapse>
          <Navbar.CollapseItem>
            <Link
              css={{
                minWidth: "100%",
              }}
              onClick={homeHandleClickScroll}
            >
              <Text
                css={{
                  color: "white ",
                  minWidth: "100%",
                }}
              >
                Home
              </Text>
            </Link>
          </Navbar.CollapseItem>
          <Navbar.CollapseItem>
            <Link
              css={{
                minWidth: "100%",
              }}
              onClick={aboutHandleClickScroll}
            >
              <Text
                css={{
                  color: "white",
                  minWidth: "100%",
                }}
              >
                About
              </Text>
            </Link>
          </Navbar.CollapseItem>
          <Navbar.CollapseItem>
            <Link
              css={{
                minWidth: "100%",
              }}
              onClick={experienceHandleClickScroll}
            >
              <Text
                css={{
                  color: "white ",
                  minWidth: "100%",
                }}
              >
                Experience
              </Text>
            </Link>
          </Navbar.CollapseItem>
          <Navbar.CollapseItem>
            <Link
              css={{
                minWidth: "100%",
              }}
              onClick={contactMeHandleClickScroll}
            >
              <Text
                css={{
                  color: "white",
                  minWidth: "100%",
                }}
              >
                Contact me
              </Text>
            </Link>
          </Navbar.CollapseItem>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}
