import { Card, Grid, Row, Text, Spacer, Container } from "@nextui-org/react";
import { motion } from "framer-motion";
import backend from "../assets/backend.png";
import creator from "../assets/creator.png";
import mobile from "../assets/mobile.png";
import web from "../assets/web.png";

const list = [
  {
    title: "Software development",
    img: backend,
  },
  {
    title: "Quality Assurance",
    img: creator,
  },
  {
    title: "Business Analysis",
    img: mobile,
  },
  {
    title: "DevOps",
    img: web,
  },
];

const fadeIn = (direction, type, delay, duration) => {
  return {
    hidden: {
      x: direction === "left" ? 100 : direction === "right" ? -100 : 0,
      y: direction === "up" ? 100 : direction === "down" ? -100 : 0,
      opacity: 0,
    },
    show: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: {
        type: type,
        delay: delay,
        duration: duration,
        ease: "easeOut",
      },
    },
  };
};

export const OverviewCard = () => {
  return (
    <Container>
      <Grid.Container justify="center" gap={3}>
        {list.map((item, index) => (
          <motion.div
            variants={fadeIn("right", "spring", index * 0.5, 1)}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.1 }}
            key={index}
          >
            <Grid>
              <Card
                justify="center"
                isHoverable
                style={{
                  maxWidth: "250px",
                  minWidth: "200px",
                  minHeight: "330px",
                  border: "2px solid transparent",
                  borderRadius: "20px",
                  background:
                    "linear-gradient(90deg, rgba(13,26,46,1) 13%, rgba(11,19,44,1) 42%, rgba(10,14,42,1) 61%, rgba(9,10,40,1) 77%, rgba(7,5,38,1) 100%), linear-gradient(112deg, #0095ff -25%, #ff4081 -10%, #9c27b0 80%)",
                  backgroundClip: "padding-box, border-box",
                  backgroundOrigin: "padding-box, border-box",
                }}
              >
                <Card.Body
                  css={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <Card.Image src={item.img} width={50} height={50} />
                </Card.Body>
                <Row justify="center">
                  <Text h4>{item.title}</Text>
                </Row>
                <Spacer y={2} />
                <Card.Footer></Card.Footer>
              </Card>
            </Grid>
          </motion.div>
        ))}
      </Grid.Container>
    </Container>
  );
};
