import React from "react";
import ReactDOM from "react-dom/client";
import { NextUIProvider, createTheme } from "@nextui-org/react";
//import reportWebVitals from "./reportWebVitals";
import App from "./App";

const theme = createTheme({
  type: "dark",
  theme: {
    colors: {
      gradient:
        "linear-gradient(112deg, $blue100 -25%, $pink500 -10%, $purple500 80%)",
      link: "#5E1DAD",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
console.log(theme);
root.render(
  <NextUIProvider theme={theme}>
    <App />
  </NextUIProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
