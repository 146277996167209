import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Text } from "@nextui-org/react";

export const Experiences = () => {
  return (
    <VerticalTimeline>
      <VerticalTimelineElement
        icon={
          <div>
            <img
              src={
                "https://yt3.googleusercontent.com/ytc/AL5GRJXb5J7e40qpYslTFiNlTJM7DTV2wS4l2rBzxg6Tmg=s900-c-k-c0x00ffffff-no-rj"
              }
              alt={"test"}
              style={{ objectFit: "cover", borderRadius: "50px" }}
            />
          </div>
        }
        className="vertical-timeline-element--work"
        date="2022 - current"
        iconStyle={{
          background: "#fff",
          color: "#fff",
        }}
        contentStyle={{
          background:
            "linear-gradient(90deg, rgba(13,26,46,1) 13%, rgba(11,19,44,1) 42%, rgba(10,14,42,1) 61%, rgba(9,10,40,1) 77%, rgba(7,5,38,1) 100%)",
          color: "#fff",
        }}
      >
        <Text h3 color="white">
          DevOps Manager
        </Text>
        <Text h5 weight="light">
          Dimension Data
        </Text>
        <ul style={{ listStyleType: "circle" }}>
          <li>
            <Text size={20} weight="bold">
              Managed and supervised the DevOps process for the organization,
              ensuring optimal performance and efficiency. Oversaw the
              deployment and operation of the systems, as well as the
              integration and delivery of software solutions. Coordinated with
              cross-functional teams to streamline processes and resolve issues,
              while also maintaining a high standard of quality control.
            </Text>
          </li>
          <li>
            <Text size={20} weight="bold">
              Providing comprehensive executive-level reporting that includes a
              wide range of key performance indicators (KPIs) to accurately
              measure and communicate the success of the project, which enabled
              the team more informed decisions.
            </Text>
          </li>
          <li>
            <Text size={20} weight="bold">
              Recent projects include:
            </Text>
            <ul style={{ listStyleType: "square" }}>
              <li>
                <Text size={20} weight="bold">
                  Assisted in the migration of code repository to a new version
                  control system, ensuring the seamless transfer of code assets
                  and maintaining data integrity throughout the process.
                  Coordinated with relevant stakeholders and teams to minimize
                  downtime and disruptions to the development process. Conducted
                  thorough testing to ensure that the migration was successful
                  and met all requirements, while also ensuring that all data
                  was properly backed up and secure.
                </Text>
              </li>
              <li>
                <Text size={20} weight="bold">
                  Helped orchestrate the setup and redevelopment of the
                  Continuous Integration/Continuous Deployment (CI/CD) pipeline
                  for a new microservice architecture, replacing a legacy
                  monolithic API. Collaborated with development teams to design
                  and implement the pipeline, ensuring smooth integration with
                  existing systems and applications. Ensured that the new
                  pipeline facilitated rapid and efficient software development,
                  testing, and deployment.
                </Text>
              </li>
            </ul>
          </li>
        </ul>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        icon={
          <div>
            <img
              src={
                "https://yt3.googleusercontent.com/ytc/AL5GRJXb5J7e40qpYslTFiNlTJM7DTV2wS4l2rBzxg6Tmg=s900-c-k-c0x00ffffff-no-rj"
              }
              alt={"test"}
              style={{ objectFit: "cover", borderRadius: "50px" }}
            />
          </div>
        }
        className="vertical-timeline-element--work"
        date="2018 - current"
        iconStyle={{
          background: "#fff",
          color: "#fff",
        }}
        contentStyle={{
          background:
            "linear-gradient(90deg, rgba(13,26,46,1) 13%, rgba(11,19,44,1) 42%, rgba(10,14,42,1) 61%, rgba(9,10,40,1) 77%, rgba(7,5,38,1) 100%)",
          color: "#fff",
        }}
      >
        <Text h3 color="white">
          Quality Assurance Manager
        </Text>
        <Text h5 weight="light">
          Dimension Data
        </Text>
        <ul style={{ listStyleType: "circle" }}>
          <li>
            <Text size={20} weight="bold">
              Overseeing all development quality gates, including UX,
              automation, security, performance, and API areas. Actively
              involved in ensuring that each of the areas adhere to established
              standards and best practices. Closely monitoring the development
              process at every stage, ensuring that all potential issues are
              identified and addressed in a timely manner to ensure high quality
              outcomes
            </Text>
          </li>
          <li>
            <Text size={20} weight="bold">
              As a Quality Assurance Manager, I led a team of 15 Quality
              Assurance Analysts during the height of the project. In this role,
              I am responsible for managing their workload, ensuring they are
              meeting project goals and deadlines, and maintaining a high level
              of quality in their work. I also provide coaching and mentorship
              to help them grow and develop in their roles. Through effective
              communication and collaboration, I ensure that the team is aligned
              and focused on delivering quality outcomes that meet the project's
              requirements
            </Text>
          </li>
        </ul>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        icon={
          <div>
            <img
              src={
                "https://yt3.googleusercontent.com/ytc/AL5GRJXb5J7e40qpYslTFiNlTJM7DTV2wS4l2rBzxg6Tmg=s900-c-k-c0x00ffffff-no-rj"
              }
              alt={"test"}
              style={{ objectFit: "cover", borderRadius: "50px" }}
            />
          </div>
        }
        contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
        date="2017 - 2018"
        iconStyle={{
          background: "#fff",
          color: "#fff",
        }}
        contentStyle={{
          background:
            "linear-gradient(90deg, rgba(13,26,46,1) 13%, rgba(11,19,44,1) 42%, rgba(10,14,42,1) 61%, rgba(9,10,40,1) 77%, rgba(7,5,38,1) 100%)",
          color: "#fff",
        }}
      >
        <Text h3 color="white">
          Junior Business Analyst
        </Text>
        <Text h5 weight="light">
          Dimension Data
        </Text>
        <ul style={{ listStyleType: "circle" }}>
          <li>
            <Text size={20}>
              As a Business analyst, I was responsible for creating and updating
              all specification documentation throughout the software life
              cycle, for both new and existing requirements. This includes
              gathering and analyzing business requirements, writing technical
              specifications, and ensuring that all documentation is accurate,
              complete, and up-to-date. My focus on maintaining clear and
              concise documentation ensured that all stakeholders had a deep
              understanding of the project and its requirements, minimizing
              errors and rework.
            </Text>
          </li>
          <li>
            <Text size={20}>
              I lead Joint Application Design (JAD) sessions with clients to
              come up with the best solution possible. My approach centered
              around active listening, collaboration, and a deep understanding
              of the client's needs and requirements. I facilitated
              brainstorming sessions that allow all stakeholders to contribute
              their ideas, ensuring that all perspectives are heard and
              considered. With my ability to lead effective JAD sessions, I
              helped ensure that the solutions we developed are not only
              functional but also aligned with the client's vision and
              objectives.
            </Text>
          </li>
        </ul>
      </VerticalTimelineElement>
    </VerticalTimeline>
  );
};
