import { Spacer } from "@nextui-org/react";

import { Outlet } from "react-router-dom";

import MainNavigation from "../components/shared/ui/MainNavigation";

import { authActions } from "../store/auth-slice";
import { useDispatch } from "react-redux";
import Auth from "../components/Auth";
import { useState } from "react";

function RootLayout() {
  const [authMode, updateAuthMode] = useState(false);
  const dispatch = useDispatch();
  const [modalIsVisible, setModalIsVisible] = useState(false);

  const signUpModeHandler = () => {
    updateAuthMode(false);
    setModalIsVisible(true);
  };

  const loginModeHandler = () => {
    updateAuthMode(true);
    setModalIsVisible(true);
  };

  const loginHandler = () => {
    dispatch(authActions.toggleLogin());
  };

  const closeModalHandler = () => {
    setModalIsVisible(false);
  };

  return (
    <>
      <Auth
        authMode={authMode}
        modalIsVisible={modalIsVisible}
        setModalIsVisible={setModalIsVisible}
        signUpModeHandler={signUpModeHandler}
        closeModalHandler={closeModalHandler}
        loginModeHandler={loginModeHandler}
      />

      <MainNavigation
        authMode={authMode}
        modalIsVisible={modalIsVisible}
        setModalIsVisible={setModalIsVisible}
        signUpModeHandler={signUpModeHandler}
        closeModalHandler={closeModalHandler}
        loginModeHandler={loginModeHandler}
      />
      <Spacer y={3} />
      <Outlet />
    </>
  );
}

export default RootLayout;
